import React, { useEffect, useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom';
import socket from './socketconnection1';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import amountClickCss from '../css/turnamentStage.module.css'
export const Amountcllick = () => {
    const location = useLocation();
    const history = useHistory();
    const [activeTab, setActiveTab] = useState("winning");
    const { id } = location.state.state;
    const [list, setList] = useState({});
    const [game, setGame] = useState({});



    function getList(id) {
        socket.emit('getRank', id, (msg) => {
            // console.log(msg,'msg')
            if(msg){
                setList(msg)
            }
           
        })
    }
    const handleClick = (tab) => {
        setActiveTab(tab);
        if (tab === 'leaderboard') {
            getList(id);
        }
    };

    function handleJoin() {
        const token = localStorage.getItem("token");
        console.log(id, 'heloo')
        socket.emit('joinContest', { id, token }, (msg) => {
            console.log('msf', msg)
            if (msg.status) {
                localStorage.setItem('playerId', msg.playerId);
                history.push(`/turnament/${msg.game}`, { state: { position: msg.position, id } })
            } else {
                toast.error(msg.message, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }
        })
    }

    const prizeList = [
        {
            id: 1,
            player: 120,
            win: 50,
            price: 0,
            prizeDistribution: [
                { rankRange: [1, 1], prize: 10 },
                { rankRange: [2, 3], prize: 5 },
                { rankRange: [4, 33], prize: 1 }
            ]
        },
        {
            id: 2,
            player: 120,
            win: 1000,
            price: 10,
            prizeDistribution: [
                { rankRange: [1, 1], prize: 200 },
                { rankRange: [2, 3], prize: 100 },
                { rankRange: [4, 33], prize: 20 }
            ]
        },
        {
            id: 3,
            player: 12,
            win: 100,
            price: 10,
            prizeDistribution: [
                { rankRange: [1, 1], prize: 50 },
                { rankRange: [2, 2], prize: 20 },
                { rankRange: [3, 3], prize: 10 },
                { rankRange: [4, 7], prize: 5 },
            ]
        },
        {
            id: 4,
            player: 12,
            win: 500,
            price: 50,
            prizeDistribution: [
                { rankRange: [1, 1], prize: 250 },
                { rankRange: [2, 2], prize: 100 },
                { rankRange: [3, 3], prize: 50 },
                { rankRange: [4, 7], prize: 25 },
            ]
        },
        {
            id: 5,
            player: 12,
            win: 1000,
            price: 100,
            prizeDistribution: [
                { rankRange: [1, 1], prize: 500 },
                { rankRange: [2, 2], prize: 200 },
                { rankRange: [3, 3], prize: 100 },
                { rankRange: [4, 7], prize: 50 },
            ]
        },
        {
            id: 6,
            player: 4,
            win: 150,
            price: 50,
            prizeDistribution: [
                { rankRange: [1, 1], prize: 150 },
            ]
        },
        {
            id: 7,
            player: 4,
            win: 300,
            price: 100,
            prizeDistribution: [
                { rankRange: [1, 1], prize: 300 },
            ]
        },
        {
            id: 8,
            player: 4,
            win: 1000,
            price: 300,
            prizeDistribution: [
                { rankRange: [1, 1], prize: 1000 },
            ]
        }

    ]

    useEffect(() => {
        const data = prizeList.find(data => data.id === id);
        setGame(data);
    }, [])

    useEffect(()=>{
        console.log(game)
    },[game])



    return (
        <>
            <div className="leftContainer leftContainer-Ludo">
                <div className={amountClickCss.joinMain}>
                    <div className={amountClickCss.joinMainA}>
                        <Link to='/'><i className="fas fa-times"></i></Link>
                    </div>
                    <div className={amountClickCss.joinMainB}>
                        <button onClick={handleJoin}>₹{game.price} JOIN</button>
                    </div>
                </div>
                <div className={amountClickCss.tabsForwins}>
                    <div className={amountClickCss.tabsWining} style={{ borderBottom: activeTab === 'winning' ? '2px solid #3f51b5' : 'none' }} onClick={() => handleClick('winning')}>
                        <p style={{ color: activeTab === 'winning' ? '#3f51b5' : 'rgb(0 0 0 / 54%)' }}>WINNINGS</p>
                    </div>
                    <div className={amountClickCss.tabsLeader} style={{ borderBottom: activeTab === 'leaderboard' ? '2px solid #3f51b5' : 'none' }} onClick={() => handleClick('leaderboard')}>
                        <p style={{ color: activeTab === 'leaderboard' ? '#3f51b5' : 'rgb(0 0 0 / 54%)' }}>LEADERBOARD</p>
                    </div>
                </div>
                <div>
                    {activeTab === 'winning' && (
                        <>
                            <div className={amountClickCss.winningTabMain}>
                                <div className={amountClickCss.winningTabMainA}>
                                    <div className={amountClickCss.winningTabMainAPart1}>
                                        Prizes
                                    </div>
                                    <div className={amountClickCss.winningTabMainAPart2}>
                                        ₹ {game.win}
                                        <span>{game.player} Winners</span>
                                    </div>
                                </div>
                                <div className={amountClickCss.winningTabMainB}>
                                    <p>Distribution</p>
                                </div>
                                <div className={amountClickCss.winningTabMainC}>

                                    {game.prizeDistribution?game.prizeDistribution.map((item) => (
                                        <div className={amountClickCss.winningTabMainC1}>
                                            <p className={amountClickCss.winningTabMainC1P1}>{item.rankRange[0] === item.rankRange[1] ? item.rankRange[0] : `${item.rankRange[0]}-${item.rankRange[1]}`}</p>
                                            <p className={amountClickCss.winningTabMainC1P2}>₹{item.prize}</p>
                                        </div>
                                    )):''}

                                </div>
                                <div className={amountClickCss.winningTabMainD}>
                                    <div className={amountClickCss.winningTabMainD1}>
                                        Prize pool of this contest is guaranteed
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {activeTab === 'leaderboard' && (
                       <>
                       {list.position ? (
                           <>
                               <div className={amountClickCss.leaderboardMain}>
                                   <input type="range" className="form-range" id="customRange1" value="32" />
                                   <div className={amountClickCss.leaderboardMainDiv}>
                                       {list.position?.length}/{list.size ? list.size : ""} Entries
                                   </div>
                               </div>
                   
                               <div className={amountClickCss.leaderboardMain2}>
                                   <div className={amountClickCss.leaderboardMain2A}>
                                       <p className={amountClickCss.leaderboardMain2AP1}>#</p>
                                       <p className={amountClickCss.leaderboardMain2AP2}>Entries</p>
                                       <p className={amountClickCss.leaderboardMain2AP3}>View</p>
                                       <p className={amountClickCss.leaderboardMain2AP4}>Score</p>
                                   </div>
                                   {list.position?.map((item) =>
                                       item.status === 1 ? (
                                           <div key={item.player.Name} className={amountClickCss.leaderboardMain2B}>
                                               <p className={amountClickCss.leaderboardMain2BP1}>1</p>
                                               <p className={amountClickCss.leaderboardMain2BP2}>
                                                   <img src="/Images/yodhaContaxt/profile.png" alt="" />
                                                   {item.player.Name}
                                               </p>
                                               <p className={amountClickCss.leaderboardMain2BP3}>
                                                   <i className="fas fa-eye"></i>
                                               </p>
                                               <p className={amountClickCss.leaderboardMain2BP4}>{item.points}</p>
                                           </div>
                                       ) : null
                                   )}
                               </div>
                           </>
                       ) : ''
                       }
                   </>
                   
                    )}
                </div>
                <ToastContainer />
            </div>
        </>
    )

}
