
const initialState = {
  api1Data: [],
};

export const dataReducer = (state = initialState, action) => {
  // // console.log("Reducer action type:", action.type);
  // // console.log("Reducer state:", state);
  switch (action.type) {
    case "FETCH_API1_DATA_SUCCESS":
      return {
        ...state,
        api1Data: action.payload, // Update API 1 data
        error: null // Clear error message
      };
    case "FETCH_API1_DATA_FAILURE":
      return {
        ...state,
        api1Data: [], // Clear API 1 data
        error: action.payload // Update error message
      }; 
    default:
      return state; // Return current state if action type is not recognized
  }
};