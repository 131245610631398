import React from 'react';
import { COORDINATES_MAP, STEP_LENGTH, BASE_POSITIONS } from './constants';

const PlayerPiece = ({ playerId, pieceId, position, onClick, game }) => {
  const handleClick = (e) => {
    console.log(e.target)
    onClick(playerId, pieceId, e);
  };

  const [x, y] = COORDINATES_MAP[position];

  return (
    <div
      className="player-piece"
      style={{
        top: `${y * STEP_LENGTH}%`,
        left: `${x * STEP_LENGTH}%`,
        position: 'absolute',
        zIndex: `${playerId === 'P1' ? "99" : '1'}`
      }}
    >
      <img src={`/image/images/${playerId}.png`} alt={`${playerId} piece`} player-Id={playerId} piece={pieceId} onClick={handleClick}
        style={{ display: 'block', width: "90%", margin: "auto" }} />
      {(playerId !== 'P1' && !BASE_POSITIONS[playerId].includes(position) && game === 'Turnament') ? <div style={{ width: '17px', height: '17px', position: 'absolute', borderRadius: '50%', background: '#867979', fontSize: '11px', top: '0%', right: "0%", color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>{playerId === 'P2' ? position > 26 ? position - 26 : position + 26 : playerId === 'P3' ? position < 39 ? position + 13 : position - 39 : position < 13 ? position + 39 : position - 13}</div> : ''}
    </div>
  );
};

export default PlayerPiece;
