import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Homepage from "./uiComponents/Homepage";

import Landing from "./uiComponents/Landing";
import userLogin from "./uiComponents/Login";
import Mywallet from "./uiComponents/Mywallet";
import Addcase from "./uiComponents/Addcase";

import Withdrawopt from "./uiComponents/Withdrawopt";
import Profile1 from "./uiComponents/Profile1";
import ViewGame1 from "./uiComponents/ViewGame1";
import Gamehistory from "./uiComponents/Gamehistory";
import "animate.css";
import axios from "axios";

import Transactionhistory from "./uiComponents/Transactionhistory";
import Referralhis from "./uiComponents/Referralhis";
import Refer from "./uiComponents/Refer";
import Notification from "./uiComponents/Notification";
import Support from "./uiComponents/Support";

// import Games from './uiComponents/Games';
// import Kyc from './uiComponents/Kyc';
import Kyc2 from "./uiComponents/Kyc2";
// import kyc3 from './uiComponents/kyc3';
import RefundPolicy from "./uiComponents/RefundPolicy";
import terms_condition from "./uiComponents/terms_condition";
import PrivacyPolicy from "./uiComponents/PrivacyPolicy";
import Gamerules from "./uiComponents/Gamerules";
import ResponsibleGaming from "./uiComponents/ResponsibleGaming";
import Return from "./uiComponents/Return";
import Notify from "./uiComponents/Notify";
import Header from "./Components/Header";
import Rightcontainer from "./Components/Rightcontainer";
// import Downloadbutton from "./Components/Downloadbutton";
import Redeem from "./uiComponents/Redeem";
import AboutUs from "./uiComponents/AboutUs";
import HomepageGame from "./uiComponents/Battellist";
// import getSocket from "./uiComponents/socketconnection";
import LudoBoard from "./uiComponents/LudoBorad";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import BetStage from "./uiComponents/betStage";
import { Yodhacontaxt } from "./uiComponents/YodhaContaxt";
import { useDispatch } from "react-redux";
import { fetchData } from "../RaectRedux/ActionApiOfData";
import LudoBoardTurnament from "./uiComponents/ludoTurnamentBoard";
import { Amountcllick } from "./uiComponents/turnamentStage";

const App2 = () => {
  const dispatch = useDispatch();
  useEffect(()=>{
  dispatch(fetchData());
  },[])
  //const [mount, setMount] = useState(true)
  //const history = useHistory()
  // const socket = getSocket();
  // if(socket){
  //   console.log("socket is connected !", socket)
  // }

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const match1 = useRouteMatch("/ludo/:gameId");
  const match2 = useRouteMatch("/turnament/:Id");
  const gameId1 = match1?.params || match2?.params || {};
  const gameId = gameId1.Id || gameId1.gameId;
  console.log("gameId with parmas ",gameId);
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const access_token = localStorage.getItem("token");
  const [user, setUser] = useState();
  return (
    <>
     {!gameId && (
        <div className="leftContainer">
          <div>
            <Header/>
          </div>
        </div>
      )}
      {!access_token ? (
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/landing" component={Landing} />
          <Route path="/login" component={userLogin} />
          <Route path="/RefundPolicy" component={RefundPolicy} />
          <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route path="/term-condition" component={terms_condition} />
          <Route path="/about" component={AboutUs} />
          <Route path="/refund-policy" component={RefundPolicy} />
          <Route path="/contact-us" component={Support} />
          <Route path="/Gamerules" component={Gamerules} />
          <Route path="/responsible-gaming" component={ResponsibleGaming} />
          <Redirect to="/login" />
        </Switch>
      ) : (
        <Switch>
          <Route path="/transaction-history" component={Transactionhistory} />
          {/* <Route exact path="/transaction" component={Transactionhistory} /> */}
          <Route exact path="/Referral-history" component={Referralhis} />
          <Route exact path="/landing" component={Landing} />
          <Route exact path="/Gamehistory" component={Gamehistory} />
          {/* <Route exact path="/profile" component={Profile} /> */}
          <Route
            exact
            path="/HomePage/:Game"
            render={() => <Homepage/>}
          />
           <Route
            exact
            path="/HomePagegame/Ludogame"
            render={() => <HomepageGame/>}
          />
           <Route exact path="/ludo/:gameId" component={LudoBoard} />
           <Route exact path="/ludoGame/betstage" component={BetStage} />
           <Route exact path="/yodhaContaxt" component={Yodhacontaxt} />
          <Route exact path="/refer" component={Refer} />
          <Route exact path="/Notification" component={Notification} />
          <Route exact path="/" component={Landing} />
          <Route path="/profile" component={Profile1} />
          <Route path="/viewgame1/:id" component={ViewGame1} />
          <Route exact path="/turnament/:Id" component={LudoBoardTurnament} />
          <Route path="/turnamentStage" component={Amountcllick} />
          
          <Route
            path="/addcase"
            render={() => <Addcase />}
          />

          <Route
            path="/Withdrawopt"
            render={() => <Withdrawopt/>}
          />
          <Route path="/wallet" component={Mywallet} />
          <Route path="/support" component={Support} />

          {/* <Route path="/Games" component={Games} /> */}
          <Route exact path="/landing/:id" component={Landing} />
          <Route path="/kyc2" render={() => <Kyc2 user={user} />} />
          <Route path="/Rules" component={Gamerules} />
          <Route path="/RefundPolicy" component={RefundPolicy} />
          <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route path="/term-condition" component={terms_condition} />
          {/* <Route path="/timer" component={Timer}/> */}
          <Route
            path="/return"
            render={() => <Return/>}
          />
          <Route path="/notify" component={Notify} />

          <Route
            path="/Redeem"
            render={() => <Redeem/>}
          />
          <Route path="/contact-us" component={Support} />
          <Route path="/refund-policy" component={RefundPolicy} />
          <Route path="/Gamerules" component={Gamerules} />
          <Route path="/responsible-gaming" component={ResponsibleGaming} />
          <Route path="/about" component={AboutUs} />

          <Redirect to="/landing" />
        </Switch>
      )}
      <div className="rightContainer">
        <Rightcontainer />
      </div>

      {/* <h2>Install Demo</h2> */}
    </>
  );
};
export default App2;
