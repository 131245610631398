import axios from "axios";
export const fetchData = () => {
  const access_token = localStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  return async (dispatch) => {
   
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      axios
        .get(baseUrl + `me`, { headers })
        .then((res) => {
          dispatch({
            type: "FETCH_API1_DATA_SUCCESS",
            payload: res.data
          });
        })
        .catch((e) => {
          if (e.response?.status === 401) {
            localStorage.removeItem("token");
            dispatch({
              type: "FETCH_API1_DATA_FAILURE",
              payload: e.message
            });
          
          }
        });
      
  
  };
};


    
    

