import React, { useCallback, useState, useEffect } from 'react'
// import '../components/common.css'
import { Link } from 'react-router-dom';
import homeCSS from '../css/yodhaContext.module.css';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import socket from './socketconnection1';

export const Yodhacontaxt = () => {
     const [pending, setPending] = useState([]);
     const [joined, setJoined] = useState([]);

     useEffect(() => {
          socket.emit('allTurnament', {}, (error, msg) => {
               if (error) {
                    console.log(error)
               }
               else {
                    console.log(msg)
                    setJoined(msg);
               }

          })
     }, [])

     const history = useHistory();
     const [activeTab, setActiveTab] = useState("contests");
     const handleClick = (tab) => {
          setActiveTab(tab);
          if (tab === 'mycontests') {
               getPending();
          }
     };
     function getPending() {
          const playerId = localStorage.getItem('token');
          console.log(playerId, 'playerId');
          socket.emit('pendingGame', { playerId }, (msg) => {
               console.log(msg, 'games');
               setPending(msg);

          })
     }
     function handleJoinPending(id) {
          socket.emit('rejoin', id, (msg) => {
               localStorage.setItem('playerId', msg.playerId)
               // console.log(msg);
               history.push(`/turnament/${id}`, { state: { position: msg.gameState, id: msg.id, rejoin: true, diceTime: msg.diceTimer, peiceTime: msg.peiceTimer,valueDice:msg.dice,missed:msg.missed,score:msg.score,remaining:msg.count } })
          })
     }
     function handleJoin(id) {
          history.push('/turnamentStage', { state: { id } })
     }
     const contestSections = [
          {
               heading: "Free Contest",
               contests: [
                    {
                         id: 1,
                         letter: "S",
                         prize: "₹50",
                         entryFee: "₹0",
                         progress: 32,
                         entries: "120",
                         maxEntries: "max 1 entries / per user",
                    },
               ],
          },
          {
               heading: "Mega Contest 😍😍",
               contests: [
                    {
                         id: 2,
                         letter: "M",
                         prize: "₹1000",
                         entryFee: "₹10",
                         progress: 60,
                         entries: "120",
                         maxEntries: "max 2 entries / per user",
                    },
               ],
          },
          {
               heading: "Trending Now",
               contests: [
                    {
                         id: 3,
                         letter: "M",
                         prize: "₹100",
                         entryFee: "₹10",
                         progress: 32,
                         entries: "12",
                         maxEntries: "max 2 entries / per user",
                    },
                    {
                         id: 4,
                         letter: "M",
                         prize: "₹500",
                         entryFee: "₹50",
                         progress: 32,
                         entries: "12",
                         maxEntries: "max 2 entries / per user",
                    },
                    {
                         id: 5,
                         letter: "M",
                         prize: "₹1000",
                         entryFee: "₹100",
                         progress: 32,
                         entries: "12",
                         maxEntries: "max 2 entries / per user",
                    },
               ],
          },
          {
               heading: "Winner Takes All!",
               contests: [
                    {
                         id: 6,
                         letter: "S",
                         prize: "₹150",
                         entryFee: "₹50",
                         progress: 32,
                         entries: "4",
                         maxEntries: "max 1 entries / per user",
                    },
                    {
                         id: 7,
                         letter: "M",
                         prize: "₹ 300",
                         entryFee: "₹100",
                         progress: 32,
                         entries: "4",
                         maxEntries: "max 2 entries / per user",
                    },
                    {
                         id: 8,
                         letter: "M",
                         prize: "₹ 1000",
                         entryFee: "₹300",
                         progress: 32,
                         entries: "4",
                         maxEntries: "max 2 entries / per user",
                    },
               ],
          },
     ];

     return (
          <>
               <div className="leftContainer leftContainer-Ludo">
                    {/* //notify */}
                    <div className={homeCSS.notifyMain}>
                         <div className={homeCSS.notifyMainA}>
                              <p>i</p>
                         </div>
                         <div className={homeCSS.notifyMainB}>
                              <p>Update <span style={{ fontWeight: 'bold' }}>Chrome Browser</span> from play store or app store, if your game not starting.</p>
                         </div>
                    </div>
                    <div className={homeCSS.contestsMain}>
                         <div className={homeCSS.contestsMainPart1}>
                              <img src='/images/yodhaContext/contests.png' alt='' /> Contests
                         </div>
                         <div className={homeCSS.contestsMainPart2}>
                              <Link className={homeCSS.contestsMainPart2Link} to='/rules'> RULES <p>i</p></Link>
                         </div>
                    </div>
                    <div className={homeCSS.contestsParts}>
                         <div className={homeCSS.contestsPartsA} style={{ borderBottom: activeTab === 'contests' ? '2px solid #3f51b5' : 'none' }} onClick={() => handleClick('contests')}>
                              <p style={{ fontWeight: '500', color: activeTab === 'contests' ? '#3f51b5' : 'rgb(0 0 0 / 54%)', fontSize: '15px' }}>CONTESTS</p>
                         </div>
                         <div className={homeCSS.contestsPartsB} style={{ borderBottom: activeTab === 'mycontests' ? '2px solid #3f51b5' : 'none' }} onClick={() => handleClick('mycontests')}>
                              <p style={{ color: activeTab === 'mycontests' ? '#3f51b5' : 'rgb(0 0 0 / 54%)', }}>MY CONTESTS</p>
                         </div>
                    </div>
                    <div className={homeCSS.whitebg}>
                         {activeTab === 'contests' && (
                              <>
                                   {contestSections.map((section) => (
                                        <div key={section.heading} className={homeCSS.freeContestMain}>
                                             <div className={homeCSS.freeContestMain2}>
                                                  <h5>{section.heading}</h5>
                                                  {section.contests.map((contest) => (
                                                       <div key={contest.id} className={homeCSS.freeContestMain2A}>
                                                            <div className={homeCSS.freeContestMain2APart1}>
                                                                 <p>{contest.letter}</p>
                                                            </div>
                                                            <div className={homeCSS.freeContestMain2APart2}>
                                                                 <div className={homeCSS.freeContestMain2APart2A}>
                                                                      WIN {contest.prize}
                                                                 </div>
                                                                 <div className={homeCSS.freeContestMain2APart2B}>
                                                                      <Link onClick={() => { handleJoin(contest.id) }} style={{ textDecoration: "none" }}>
                                                                           <p>{contest.entryFee}</p>
                                                                      </Link>
                                                                 </div>
                                                            </div>
                                                            <div className={homeCSS.freeContestMain2APart3}>
                                                                 <input type="range" className="form-range" id={`customRange${contest.id}`} value={contest.progress} readOnly />
                                                                 <div className={homeCSS.freeContestMain2APart3A}>
                                                                      <div className={homeCSS.freeContestMain2APart3A1}>
                                                                           {joined.find(item=>item.gameType===contest.id)?.positionLength ?? 0} / {contest.entries} entries
                                                                      </div>
                                                                      <div className={homeCSS.freeContestMain2APart3A2}>
                                                                           {contest.maxEntries}
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  ))}
                                             </div>
                                        </div>
                                   ))}
                                   <div className={homeCSS.freeContestMain2APart3A3}>
                                   </div>
                              </>
                         )}
                         {activeTab === 'mycontests' && (
                              pending.length < 1 ?
                                   <div className={homeCSS.mycontestsMain}>
                                        <img src='/Maintenance.jpg' alt='' />
                                        <p className={homeCSS.mycontestsMainP1}>GAME IS IN MAINTENANCE!</p>
                                        <p className={homeCSS.mycontestsMainP2}>Please stay connected, it will get resolved within 15 </p>
                                        <p className={homeCSS.mycontestsMainP3}>
                                             minutes!
                                        </p>
                                   </div>
                                   :
                                   <>
                                        {/* {pending.map((section) => ( */}
                                        <div className={homeCSS.freemyContestMain}>
                                             <div className={homeCSS.freemyContestMain2}>
                                                  {pending.map((contest) => (
                                                       <div key={contest._id} className={homeCSS.freemyContestMain2A}>
                                                            <div className={homeCSS.freemyContestMain2APart1}>
                                                                 <p></p>
                                                            </div>
                                                            <div className={homeCSS.freemyContestMain2APart2}>
                                                                 <div className={homeCSS.freemyContestMain2APart2A}>
                                                                      WIN ₹{contest.gameData.winAmount}
                                                                 </div>
                                                                 <div className={homeCSS.freemyContestMain2APart2B}>
                                                                      <Link to="/amountclick" style={{ textDecoration: "none" }}>
                                                                           <p>₹{contest.gameData.price}</p>
                                                                      </Link>
                                                                 </div>
                                                            </div>
                                                            <div className={homeCSS.freemyContestMain2APart3}>
                                                                 <input type="range" className="form-range" value={((contest.gameData.positionCount) / (contest.gameData.size)) * 100} readOnly />
                                                                 <div className={homeCSS.freemyContestMain2APart3A}>
                                                                      <div className={homeCSS.freemyContestMain2APart3A1}>
                                                                           {contest.gameData.positionCount}/ {contest.gameData.size} Entries
                                                                      </div>
                                                                      <div className={homeCSS.freemyContestMain2APart3A2}>
                                                                           max {contest.gameData.maxEntries} entries per user
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <div className={homeCSS.freeContestMain2APart4}>
                                                                 <div className={homeCSS.freeContestMain2APart41}>
                                                                      <p className={homeCSS.freeContestMain2APart41P1}>My Entries</p>
                                                                      <p className={homeCSS.freeContestMain2APart41P2}>Entry 1</p>
                                                                 </div>
                                                                 <div className={homeCSS.freeContestMain2APart5}>
                                                                      <p className={homeCSS.freeContestMain2APart41P1}>Score</p>
                                                                      <p className={homeCSS.freeContestMain2APart41P2}>{contest.matchingPositions[0].points}</p>
                                                                 </div>
                                                                 <div className={homeCSS.freeContestMain2APart5}>
                                                                      <p className={homeCSS.freeContestMain2APart41P1}>Status</p>
                                                                      <p className={homeCSS.freeContestMain2APart41P2}>Running</p>
                                                                 </div>
                                                                 <div className={homeCSS.freeContestMain2APart6}>
                                                                      <button onClick={() => handleJoinPending(contest.matchingPositions[0].id)}>RE-JOIN</button>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  ))}
                                             </div>
                                        </div>
                                        {/* ))} */}
                                   </>
                         )}
                    </div>
               </div>
          </>
     )
}
